export class messagecb {
  id: number = 0;
  message: string = "";
  repeatNumber: number = 30;
  removeTime: string = "";
  pauseTime: number = 90;
  radioChannel: number = 19;
  messageText: string = "";
  audioData: string = "";

  is_current(): boolean {
    return false;
  }
}
