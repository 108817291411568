import {AfterViewInit, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {MessagesService} from "../../../services/messages.service";
import {SchemeService} from "../../../services/scheme.service";
import {Scheme} from "../../../models/scheme";
import {Znakvmsb} from "../../../models/znakvmsb";
import {MessageVMS} from "../../../models/messagevms";
import {Znakvmsc} from "../../../models/znakvmsc";
import {VMSMessage} from "../../../models/VMSMessage";

@Component({
  selector: 'app-message-scheme',
  styleUrls: ['app.message.scheme.css'],
  templateUrl: 'app.message.scheme.html',
})
export class AppMessageSchemeComponent implements OnInit {

  schemas: Scheme[] = [];
  vmsbList: Znakvmsb[] = [];

  constructor(private messageService: MessagesService, private schemeService: SchemeService) {
  }

  ngOnInit() {
    this.messageService.getVmsBList().subscribe(value => {
      this.vmsbList = value;
      this.schemeService.getSchemes().subscribe(value => {
        this.schemas = value;
      });
    });

  }

  getAllMessageVms(list: number[]): MessageVMS[] {
    console.log(list)
    let result: MessageVMS[] = [];
      for(let i=0; i<list.length; i++) {
        console.log('found '+list[i])
        result.push(this.getMessageVmsById(list[i]))
      }
    return result;
  }

  getMessageById(id:number): VMSMessage {
    return this.messageService.getMessageById(id);
  }

  getZnakById(id:number): Znakvmsb {
    for(let i=0;i<this.vmsbList.length;i++) {
      if (this.vmsbList[i].id == id) {
        return this.vmsbList[i];
      }
    }
    return new Znakvmsb();
  }

  getMessageVMSBySchema(id: number): MessageVMS[] {
    let list:MessageVMS[] = [];
    for (let i=0;i<this.schemas.length; i++) {
      if (this.schemas[i].id == id) {
        console.log('getMessageVMSBySchemaschema id ' +id)
        return this.getAllMessageVms(this.schemas[i].messages);
      }
    }

    return list;
  }

  getMessageVmsById(id): MessageVMS {
    return this.messageService.getMessageVmsById(id);
  }

}
