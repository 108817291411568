import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {User} from '../models/user';
import {map} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class UsersService {

  API_URL: string = '';
  //
  // users_update = new Observable<string>(
  //     observer => {
  //       setInterval(() =>
  //         observer.next(new Date().toString()), 1000);
  //     }
  // );

  constructor(private httpClient: HttpClient) { }

  getUsers(): Observable<User[]> {
    return this.httpClient.get(
      this.API_URL + 'users',
      {responseType: 'json'}
      ).pipe(map(val => val as User[]));
  }

  getUser(userId) {
    return this.httpClient.get(`${this.API_URL + 'users/'}/${userId}`);
  }
}
