import {AfterViewInit, Component, Input} from "@angular/core";
import {hardwareitem} from "../../models/hardwareitem";

@Component({
  selector: 'deviceinfo',
  styleUrls: ['deviceinfo.css'],
  templateUrl: 'deviceinfo.html',
})
export class DeviceinfoComponent {

  @Input() device: hardwareitem;

  constructor() {

  }

}
