<mat-vertical-stepper [linear]="isLinear" #stepper (selectionChange)="nextStep()">

  <mat-step [stepControl]="firstFormGroup">

      <ng-template matStepLabel>Układ znaku</ng-template>
      <mat-form-field>
        <mat-select (selectionChange)="formChanged($event)" required>
          <mat-option value="0" >Tylko tekst</mat-option>
          <mat-option value="1">1 znak + tekst</mat-option>
          <mat-option value="2">2 znaki</mat-option>
        </mat-select>
      </mat-form-field>
      <div>
        <button mat-button matStepperNext>Dalej</button>
      </div>

  </mat-step>

  <mat-step [stepControl]="img1Step" *ngIf="showImg1Step">
    <ng-template matStepLabel>Dodaj obraz</ng-template>
    <form [formGroup]="img1Step">
        <mat-button-toggle-group formControlName="img1Control" [(ngModel)]="image1Src" multiple="false" aria-label="Select Multi">
          <ul class="messages-list">
            <li *ngFor="let a of assets"><mat-button-toggle value="{{a}}"><img src="{{a}}"></mat-button-toggle></li>
          </ul>
        </mat-button-toggle-group>
<!--          <mat-select [(ngModel)]="image1Src" (selectionChange)="image1selectionChange()">-->
<!--            <mat-option *ngFor="let a of assets" value="{{a}}"><img src="{{a}}"/></mat-option>-->
<!--          </mat-select>-->
    </form>
      <div>
        <button mat-button matStepperPrevious>Wstecz</button>
        <button mat-button matStepperNext>Dalej</button>
      </div>
  </mat-step>

  <mat-step [stepControl]="img2Step" *ngIf="showImg2Step">

    <ng-template matStepLabel>Dodaj drugi obraz</ng-template>
    <form [formGroup]="img2Step">
      <mat-button-toggle-group formControlName="img2Control" [(ngModel)]="image2Src" multiple="false" aria-label="Select Multi">
        <ul class="messages-list">
          <li *ngFor="let a of assets"><mat-button-toggle value="{{a}}"><img src="{{a}}"></mat-button-toggle></li>
        </ul>
      </mat-button-toggle-group>
    </form>
    <div>
      <button mat-button matStepperPrevious>Wstecz</button>
      <button mat-button matStepperNext>Dalej</button>
    </div>
  </mat-step>

  <mat-step [stepControl]="secondFormGroup" *ngIf="showMessageStep">
      <ng-template matStepLabel>Treść komunikatu</ng-template>
      <div>
        Jeśli chcesz użyć definiowanej treści w schemacie, wstaw
        znak X w miejsce obliczanej wartości
      </div>
      <div>
        <mat-form-field>
          <label>Ile linii tekstu ?</label>
          <input id="lineCount" matInput type="number" value="1" min="1" max="2" (change)="numRowsChanged($event)"/>
        </mat-form-field>
      </div>
      <div>
      <mat-form-field>
        <label>Treść pierwszej linii</label>
        <input matInput placeholder="Treść" required [(ngModel)]="line1Text"/>
      </mat-form-field>
      </div>
      <div>
        <mat-form-field *ngIf="displaySecondLine">
          <label>Treść drugiej linii</label>
          <input matInput placeholder="Treść" [(ngModel)]="line2Text"/>
        </mat-form-field>
      </div>
      <div>
        <button mat-button matStepperPrevious>Wstecz</button>
        <button mat-button matStepperNext>Dalej</button>
      </div>

  </mat-step>
  <mat-step [stepControl]="lastStep" name="lastStep">

    <ng-template matStepLabel>Zapisz</ng-template>
      <div>
        <img [src]="previewFilePath" width=256 height=48 />
      </div>
      <mat-form-field>
        <input matInput placeholder="nazwa" required [(ngModel)]="messageName"/>
      </mat-form-field>

    <div>
      <button mat-button matStepperPrevious>Wstecz</button>
      <button mat-button (click)="save()">Zapisz</button>
      <button mat-button (click)="stepper.reset()">Reset</button>
    </div>

    <div *ngIf="zapisano">Zapisano !</div>
  </mat-step>
</mat-vertical-stepper>

