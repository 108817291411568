
<plotly-plot [data]="graph.data" [layout]="graph.layout"></plotly-plot>


<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

  <!-- Position Column -->
  <ng-container matColumnDef="created">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Data </th>
    <td mat-cell *matCellDef="let element"> {{element.created}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="airtemp">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> temperatura powietrza </th>
    <td mat-cell *matCellDef="let element"> {{element.airtemp/10}} &#x2103;</td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="higro">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Wilgotność względna</th>
    <td mat-cell *matCellDef="let element"> {{element.higro/10}} % </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="asphalttemp">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Temp. nawierzchni </th>
    <td mat-cell *matCellDef="let element"> {{element.asphalttemp/10}} &#x2103;</td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="earthtemp">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Temp. -6cm </th>
    <td mat-cell *matCellDef="let element"> {{element.earthtemp/10}} &#x2103;</td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="dewpoint">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Temp. rosy </th>
    <td mat-cell *matCellDef="let element"> {{element.dewpoint/10}} &#x2103;</td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="freeztemp">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Temp. zamarzania </th>
    <td mat-cell *matCellDef="let element"> 0 &#x2103;</td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="surfacecond">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nawierzchnia </th>
    <td mat-cell *matCellDef="let element"> <span *ngIf="element.solutionlayer == 0">Sucha</span> <span *ngIf="element.solutionlayer > 0">Mokra</span> </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="antifreezeqnt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Chemia koncentracja</th>
    <td mat-cell *matCellDef="let element"> 0 % </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="solutionlayer">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Chemia ilosc</th>
    <td mat-cell *matCellDef="let element"> {{element.antifreezeqnt}} mm </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="rainintensity">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Intensywność opadu </th>
    <td mat-cell *matCellDef="let element"> {{element.solutionlayer}} mm/h </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="raintype">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Typ opadu </th>
    <td mat-cell *matCellDef="let element">  <span *ngIf="element.solutionlayer == 0">Brak</span> <span *ngIf="element.solutionlayer > 0">Deszcz</span> </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="visibility">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Widzialność </th>
    <td mat-cell *matCellDef="let element"> <span *ngIf="element.solutionlayer == 0">Bardzo dobra</span> <span *ngIf="element.solutionlayer > 0">Słaba</span> </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="winddirection">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Kier. wiatru </th>
    <td mat-cell *matCellDef="let element"> {{element.winddirection/10}} &#xB0;</td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="windspeed">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Pred. wiatru </th>
    <td mat-cell *matCellDef="let element"> {{element.windspeed/10}} m/s</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<mat-paginator  [length] = "100"
                [pageSize] = "25"
                [pageSizeOptions] = "[10, 25, 50, 100]"
                (page)="getPage($event)"
>
</mat-paginator>

<button (click)="refresh()">Odświerz</button>
