import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {VMSMessage} from "../models/VMSMessage";
import {map} from "rxjs/operators";
import {MessageVMS} from "../models/messagevms";
import {Znakvmsb} from "../models/znakvmsb";
import {Znakvmsc} from "../models/znakvmsc";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Log} from "../models/log";
import {hardwareitem} from "../models/hardwareitem";

@Injectable({
  providedIn: 'root'
})
export class HardwareitemsService {

  items: hardwareitem[] = [];

  constructor(private httpClient: HttpClient) {

  }

  // pobierz listę zdefiniowanych treści
  getItems(): Observable<hardwareitem[]> {

    return this.httpClient.get(
      'hardwareitems',
      {responseType: 'json'}

    ).pipe(map(val => val as hardwareitem[]));
  }

}
