<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

  <!-- Position Column -->
  <ng-container matColumnDef="position">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
    <td mat-cell *matCellDef="let element"> <mat-checkbox class="example-margin" [(ngModel)]=element.position ></mat-checkbox></td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nazwa </th>
    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="message">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Opis </th>
    <td mat-cell *matCellDef="let element"> {{element.message}} </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Stan </th>
    <td mat-cell *matCellDef="let element">

      <mat-expansion-panel>
        <mat-expansion-panel-header>
            {{element.status}}
        </mat-expansion-panel-header>

        <button mat-raised-button color="primary">Włącz</button>

      </mat-expansion-panel>


    </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="created">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Utworzono </th>
    <td mat-cell *matCellDef="let element"> {{element.created}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<mat-paginator [length]="1"
              [pageSize]="10">
<!--              [pageSizeOptions]="pageSizeOptions"-->
<!--              (page)="pageEvent = $event">-->
</mat-paginator>
