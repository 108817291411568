<h1>Lista zmian</h1>

<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Data</th>
    <td mat-cell *matCellDef="let element"> {{element.date}} &#x2103;</td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="user_name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Użytkownik</th>
    <td mat-cell *matCellDef="let element"> {{element.user_name}} </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="message">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Treść </th>
    <td mat-cell *matCellDef="let element"> {{element.message}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
