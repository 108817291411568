<mat-card>
  <mat-card-header>
    <mat-card-title>Mop Tymień Północ</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-list>
      <mat-list-item>
        <mat-form-field>
          <mat-label>Ilość miejsc dla poj. osobowych</mat-label>
          <input matInput type="number" value="100" />
        </mat-form-field>
      </mat-list-item>
      <mat-list-item>
        <mat-form-field>
          <mat-label>Ilość zajętych miejsc dla poj. osobowych</mat-label>
          <input matInput type="number" value="10" />
        </mat-form-field>
      </mat-list-item>
      <mat-list-item>
        <mat-form-field>
          <mat-label>Ilość miejsc dla poj. ciężarowych</mat-label>
          <input matInput type="number" value="30" />
        </mat-form-field>
      </mat-list-item>
      <mat-list-item>
        <mat-form-field>
          <mat-label>Ilość zajętych miejsc dla poj. ciężarowych</mat-label>
          <input matInput type="number" value="3" />
        </mat-form-field>
      </mat-list-item>
      <mat-list-item>
        <mat-form-field>
          <mat-label>Stan parkingu</mat-label>
          <mat-chip-list>
            <mat-chip>Wolny</mat-chip>
          </mat-chip-list>
        </mat-form-field>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-header>
    <mat-card-title>Mop Tymień Południe</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-list>
      <mat-list-item>
        <mat-form-field>
          <mat-label>Ilość miejsc dla poj. osobowych</mat-label>
          <input matInput type="number" value="100" />
        </mat-form-field>
      </mat-list-item>
      <mat-list-item>
        <mat-form-field>
          <mat-label>Ilość zajętych miejsc dla poj. osobowych</mat-label>
          <input matInput type="number" value="10" />
        </mat-form-field>
      </mat-list-item>
      <mat-list-item>
        <mat-form-field>
          <mat-label>Ilość miejsc dla poj. ciężarowych</mat-label>
          <input matInput type="number" value="30" />
        </mat-form-field>
      </mat-list-item>
      <mat-list-item>
        <mat-form-field>
          <mat-label>Ilość zajętych miejsc dla poj. ciężarowych</mat-label>
          <input matInput type="number" value="3" />
        </mat-form-field>
      </mat-list-item>
      <mat-list-item>
        <mat-form-field>
          <mat-label>Stan parkingu</mat-label>
          <mat-chip-list>
            <mat-chip>Wolny</mat-chip>
          </mat-chip-list>
        </mat-form-field>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>

