import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatNativeDateModule} from '@angular/material/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {DragDropModule} from '@angular/cdk/drag-drop';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {A11yModule} from '@angular/cdk/a11y';
import {MatButtonToggle} from '@angular/material/button-toggle';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { ListaComponent } from './lista';
import { AppUserAddComponent} from './components/user/app.user.add';
import { UserService } from './services/user.service';
import {ListaComponent} from './lista';
import {AppMessageDefinitionComponent} from './components/message/definition/app.message.definition';
import {AppMessageStateComponent, VmsBox} from './components/message/state/app.message.state';
import {AppOrigListComponent} from './app.orig.list';
import {AppEventsListComponent} from './components/events/app.events.list';
import {AppMeteoListComponent} from './components/meteo/app.meteo.list';
import {AppTrafficTabsComponent} from './components/traffic/app.traffic.tabs';
import {RouterModule} from '@angular/router';
import {AppRoutingModule} from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {UsersService} from "./services/users.service";
import {MessagesService} from "./services/messages.service";
import {AppCbStateComponent} from "./components/cb/state/app.cb.state";
import {AppCbDefinitionComponent} from "./components/cb/definition/app.cb.definition";
import {LogService} from "./services/log.service";
import {CbService} from "./services/cb.service";
import {AppDetourStateComponent} from "./components/detour/app.detour.state";
import {AppLogStateComponent} from "./components/log/app.log.state";
import {AppMopStateComponent} from "./components/mop/app.mop.state";
import { LoginComponent } from './components/login/login.component';
import {MeteoService} from "./services/meteo.service";
import {AppMessageSelectComponent} from "./components/message/select/app.message.select";
import {AppMessageSchemeComponent} from "./components/message/scheme/app.message.scheme";
import {AppMessageStatesetComponent} from "./components/message/stateset/app.message.stateset";
import {AppMapComponent} from "./components/map/app.map";

import * as PlotlyJS from 'plotly.js/dist/plotly.js';
import { PlotlyModule } from 'angular-plotly.js';
import {CommonModule} from "@angular/common";
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {DeviceinfoComponent} from "./components/deviceinfo/deviceinfo";
import {HardwareitemsService} from "./services/hardwareitems.service";

PlotlyModule.plotlyjs = PlotlyJS;


@NgModule({
    imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    A11yModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    LeafletModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    PortalModule,
    ScrollingModule,
    MatBadgeModule,
    AppRoutingModule,
      PlotlyModule,
      CommonModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  entryComponents: [AppComponent],
  // ListaComponent
  declarations: [AppComponent, AppUserAddComponent, ListaComponent,
    AppMessageDefinitionComponent, AppMessageStateComponent, AppOrigListComponent,
    AppEventsListComponent, AppMeteoListComponent, AppTrafficTabsComponent,
    AppCbStateComponent, AppCbDefinitionComponent,AppDetourStateComponent,
    AppLogStateComponent, AppMopStateComponent, AppMapComponent, LoginComponent,
    DeviceinfoComponent, VmsBox, AppMessageSelectComponent, AppMessageSchemeComponent, AppMessageStatesetComponent

  ],
  bootstrap: [AppComponent],
  providers: [UsersService, MessagesService, LogService, CbService, MeteoService, HardwareitemsService]
})
export class AppModule {



}
