<mat-toolbar>
  <span>System Zarządzania Ruchem - Aplikacja Użytkownika</span>
  <span class="example-spacer"></span>
  <button *ngIf="currentUser" mat-icon-button class="example-icon" (click)="logout()" aria-label="Example icon-button with share icon">
    <mat-icon>logout</mat-icon>
  </button>
</mat-toolbar>
<app-login *ngIf="!currentUser" (userLoggedIn)="userLogin($event)"></app-login>
<mat-sidenav-container *ngIf="currentUser">
  <mat-sidenav mode="side" opened fixedInViewport disableClose fixedTopGap=90 class="panel-boczny">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header *ngIf="currentUser.username=='piotr'">Użytkownicy</mat-expansion-panel-header>
        <mat-list>
          <mat-list-item>
            <button routerLink="userlist" mat-flat-button>Lista</button>
          </mat-list-item>
          <mat-list-item>
            <button routerLink="user" mat-flat-button>Nowy</button>
          </mat-list-item>
<!--          <mat-list-item>-->
<!--            <button routerLink="userhistory" mat-flat-button>Logowania</button>-->
<!--          </mat-list-item>-->
        </mat-list>
      </mat-expansion-panel>

      <mat-list>
        <mat-list-item>
          <button routerLink="map" mat-flat-button>Mapa urządzeń</button>
        </mat-list-item>
      </mat-list>

      <mat-expansion-panel>
        <mat-expansion-panel-header>Komuniaty VMS</mat-expansion-panel-header>
        <mat-list>
          <mat-list-item>
            <button routerLink="message" mat-flat-button>Stan</button>
          </mat-list-item>
          <mat-list-item>
            <button routerLink="messagedef" mat-flat-button>Definiowanie</button>
          </mat-list-item>
<!--          <mat-list-item>-->
<!--            <button routerLink="messagescheme" mat-flat-button>Schematy</button>-->
<!--          </mat-list-item>-->
<!--          <mat-list-item>-->
<!--            <button routerlink="messagehistory" mat-flat-button>Historia</button>-->
<!--          </mat-list-item>-->
        </mat-list>
      </mat-expansion-panel>

      <mat-expansion-panel >
        <mat-expansion-panel-header>Komuniaty CB</mat-expansion-panel-header>
        <mat-list>
          <mat-list-item>
            <button routerLink="cb" mat-flat-button>Stan</button>
          </mat-list-item>
          <mat-list-item>
            <button routerLink="cbdef" mat-flat-button>Definiowanie</button>
          </mat-list-item>
          <!--          <mat-list-item>-->
          <!--            <button routerlink="messagehistory" mat-flat-button>Historia</button>-->
          <!--          </mat-list-item>-->
        </mat-list>
      </mat-expansion-panel>

<!--      <mat-expansion-panel>-->
<!--        <mat-expansion-panel-header>Organizacja ruchu</mat-expansion-panel-header>-->
<!--        <mat-list>-->
<!--          <mat-list-item>-->
            <button  routerLink="vmsc" mat-flat-button>Organizacja ruchu</button>
<!--          </mat-list-item>-->
<!--          <mat-list-item>-->
<!--              <button mat-flat-button>Historia</button>-->
<!--          </mat-list-item>-->
<!--        </mat-list>-->
<!--      </mat-expansion-panel>-->

<!--      <mat-expansion-panel>-->
<!--        <mat-expansion-panel-header>Oświetlenie</mat-expansion-panel-header>-->
<!--        <mat-list>-->
<!--          <mat-list-item>-->
<!--            <button mat-flat-button>Stan</button>-->
<!--          </mat-list-item>-->
<!--        </mat-list>-->
<!--      </mat-expansion-panel>-->

<!--      <mat-expansion-panel>-->
<!--        <mat-expansion-panel-header>Zdarzenia drogowe</mat-expansion-panel-header>-->
<!--        <mat-list>-->
<!--          <mat-list-item>-->
<!--            <button routerLink="events" mat-flat-button>Bieżące</button>-->
<!--          </mat-list-item>-->
<!--&lt;!&ndash;          <mat-list-item>&ndash;&gt;-->
<!--&lt;!&ndash;              <button mat-flat-button>Historia</button>&ndash;&gt;-->
<!--&lt;!&ndash;          </mat-list-item>&ndash;&gt;-->
<!--        </mat-list>-->
<!--      </mat-expansion-panel>-->

      <mat-expansion-panel>
        <mat-expansion-panel-header>Meteo</mat-expansion-panel-header>
        <mat-list>
          <mat-list-item>
            <button routerLink="meteo" mat-flat-button>Stan</button>
          </mat-list-item>
<!--          <mat-list-item>-->
<!--              <button routerLink="meteohistory" mat-flat-button>Historia</button>-->
<!--          </mat-list-item>-->
        </mat-list>
      </mat-expansion-panel>

    </mat-accordion>

    <mat-list>
      <mat-list-item>
        <button routerLink="traffic" mat-flat-button>Pomiar ruchu</button>
      </mat-list-item>

<!--      <mat-list-item>-->
<!--        <button routerLink="mop" mat-flat-button>MOP</button>-->
<!--      </mat-list-item>-->


      <mat-list-item >
        <button routerLink="log" mat-flat-button>Rejestr zmian</button>
      </mat-list-item>

    </mat-list>

<!--    -->
<!--    <mat-accordion>-->
<!--      <mat-expansion-panel>-->
<!--        <mat-expansion-panel-header>System</mat-expansion-panel-header>-->
<!--        <mat-list>-->
<!--          -->
<!--&lt;!&ndash;          <mat-list-item>&ndash;&gt;-->
<!--&lt;!&ndash;              <button mat-flat-button>Zdarzeń</button>&ndash;&gt;-->
<!--&lt;!&ndash;          </mat-list-item>&ndash;&gt;-->
<!--        </mat-list>-->
<!--      </mat-expansion-panel>-->

<!--    </mat-accordion>-->

  </mat-sidenav>

  <mat-sidenav-content role="main" class="panel-glowny">
<!--  -->
    <router-outlet></router-outlet>
<!--    <app-user-add>Loading...</app-user-add>-->
<!--    <app-lista-component>Loading... </app-lista-component>-->
<!--    <app-message-definition>Loading...</app-message-definition>-->
<!--    <app-message-state>Loading...</app-message-state>-->
<!--    <app-orig-list>Loading...</app-orig-list>-->
<!--    <app-events-list>Loading...</app-events-list>-->
<!--    <app-meteo-list>Loading...</app-meteo-list>-->
<!--    <app-traffic-tabs>Loading...</app-traffic-tabs>-->
<!--  -->
  </mat-sidenav-content>
</mat-sidenav-container>




