
  <mat-card *ngFor="let message of messages">
    <mat-card-title>{{message.message}}</mat-card-title>
    <mat-card-content>
        <div class="szrau details">
          <span class="duors-text">Treść: {{message.messageText}}</span>
          <span class="duors-text">Data zakończenia: {{message.removeTime}}</span>
          <span class="duors-text">Ilość powtórzeń: {{message.repeatNumber}}</span>
          <span class="duors-text">Odstęp: {{message.pauseTime}}</span>
          <span class="duors-text">Kanał: {{message.radioChannel}}</span>
        </div>
        <div>
          <audio controls>
            <source src="{{message.audioData}}" type="audio/mp3">
            Your browser does not support the audio element.
          </audio>
        </div>
    </mat-card-content>
    <mat-card-actions>
      <div class="duors-v-center-outer"><span class="duors-v-center">{{message.messageText}}</span></div>
      <div class="duors-v-center-outer" *ngIf="message.is_current()"><span class="duors-v-center"><mat-button-toggle (click)="wylacz(message)">Wyłącz</mat-button-toggle></span></div>
      <div class="duors-v-center-outer" *ngIf="!message.is_current()"><span class="duors-v-center"><mat-button-toggle (click)="wlacz(message)">Wyłącz</mat-button-toggle></span></div>
    </mat-card-actions>
  </mat-card>
