import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MessagesService} from "../../../services/messages.service";
import {VMSMessage} from "../../../models/VMSMessage";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Znakvmsb} from "../../../models/znakvmsb";
import {MatDialogRef} from '@angular/material/dialog';
import {Scheme} from "../../../models/scheme"
import {SchemeService} from "../../../services/scheme.service";
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

/**
 * @title Stepper overview
 */
@Component({
  selector: 'app-message-select',
  templateUrl: 'app.message.select.html',
  styleUrls: ['app.message.select.css'],
})
export class AppMessageSelectComponent implements OnInit {

  messagesService: MessagesService;
  formBuilder: FormBuilder;
  dialogRef: MatDialogRef<AppMessageSelectComponent>;
  @Output() close = new EventEmitter();
  schemasService: SchemeService;

  messages: VMSMessage[] = [];
  vmsblist: Znakvmsb[] = [];
  schemes: Scheme[] = [];
  selectTime: FormGroup;
  selectSchemeTime: FormGroup;
  selectSingle: FormGroup;
  selectMulti: FormGroup;
  selectScheme: FormGroup;
  selectSchemeVMS: FormGroup;
  selectSchemeLocation: FormGroup;
  spinnerVisible: boolean = false;

  constructor(messagesService: MessagesService, formBuilder: FormBuilder,
              dialogRef: MatDialogRef<AppMessageSelectComponent>, schemasService: SchemeService,
              @Inject(MAT_DIALOG_DATA) public data: any
              ) {
    this.messagesService = messagesService;
    this.formBuilder = formBuilder;
    this.dialogRef = dialogRef;
    this.schemasService = schemasService;


    this.selectSingle = this.formBuilder.group({
      messageSingle: ['', Validators.required]
    });
    this.selectMulti = this.formBuilder.group({
      messageMulti: ['', Validators.required]
    });
    this.selectTime = this.formBuilder.group({
      multiTime: ['', Validators.required]
    });

    this.selectScheme = this.formBuilder.group({
      messageScheme: ['', Validators.required]
    });
    this.selectSchemeTime  = this.formBuilder.group({
      schemeTime: ['', Validators.required]
    });
    this.selectSchemeVMS  = this.formBuilder.group({
      schemeVMS: ['', Validators.required]
    });
    this.selectSchemeLocation  = this.formBuilder.group({
      schemeLocation: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.messagesService.getMessages().subscribe(value => {
      this.messages = value;
    })
    this.messagesService.getVmsBList().subscribe(value => {
      this.vmsblist = value;
    })
    this.schemasService.getSchemes().subscribe(value => {
      this.schemes = value;
    })
  }

  showSpinner() {
    this.spinnerVisible = true;
  }

  hideSpinner() {
    this.spinnerVisible = false;
  }

  saveSingle() {
    this.showSpinner()
    this.messagesService.setVmsMessage(this.data.id, this.selectSingle.get('messageSingle').value).subscribe(value => {
      this.hideSpinner()
      this.dialogRef.close(value)
    });
  }

  saveMulti() {
    this.dialogRef.close()
  }

  saveScheme() {
    // this.dialogRef.close()
  }

  isMany() {
    // console.log(this.selectMulti.get("messageMulti"))
    if  (true) {
      return true;
    }
    return false;
  }

  onCloseClick() {
    console.log("close")
    this.dialogRef.close(false)
  }
}
