import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import {User} from "../../models/user";
import {UserService} from "../../services/user.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  login: string;
  haslo: string;
  veri: boolean = false;
  showSpinner: boolean = true;
  showLogin: boolean = false;
  userService: UserService;
  @Output() userLoggedIn = new EventEmitter<User>();

  constructor(userService: UserService) {
    this.userService = userService
  }

  ngOnInit(): void {
    this.checkUser()
  }

  checkUser() {
    this.userService.check().subscribe(user => {
      if (user.id != 0) {
        this.userLoggedIn.emit(user)
      } else {
        this.showLoginDialog()
      }
    })
  }

  showLoginDialog() {
    this.showSpinner = false
    this.showLogin = true
  }

  hideLoginDialog() {
    this.showSpinner = true
    this.showLogin = false
  }

  logoj() {
    this.hideLoginDialog()
    this.userService.login(this.login, this.haslo).subscribe(
      user => {
        if (user.id != 0) {
          this.userLoggedIn.emit(user)
        } else {
          this.showLoginDialog()
          this.veri = true;
        }
      });
  }

}
