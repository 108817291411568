import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {Meteo} from "../models/meteo";

@Injectable({
  providedIn: 'root'
})
export class MeteoService{

  url = "getmeteo";
  data: Meteo[] = [];

  constructor(private httpClient: HttpClient) { }

  getMeteo(page: number = 0, items: number = 25): Observable<Meteo[]> {

    let observable = new Observable<Meteo[]>((observer) => {
        return this.httpClient.get(this.url, {responseType: 'json', params: {"page": page.toString(), "items": items.toString()}}).pipe(
          map(val => val as Meteo[])
        ).subscribe(value => {
          this.data = value
          observer.next(this.data)
        })
    })

    return observable
  }

}
