
<mat-card *ngFor="let s of schemas">
  <mat-card-title>
    <span class="duors-v-center">{{s.name}}</span>
  </mat-card-title>
  <mat-card-content>
    <ul class="messages-list">
      <li *ngFor="let mvms of getMessageVMSBySchema(s.id)">
<!--        ID: {{s.id}} MVMS: {{mvms}}-->
        <div class="message-vms">{{getZnakById(mvms.vms_id)}}</div>
        <div class="message-content"><img src="{{getMessageById(mvms.message_id).path}}"/></div>
      </li>
    </ul>
  </mat-card-content>
</mat-card>
