export class User {

    id: number;
    first_name: string;
    last_name: string;
    last_login: string;
    date_joined: string;
    is_active: boolean
    email: string;
    username: string;
    permissions: string[];

    can(what:string):boolean {
      if(this.permissions.indexOf(what) > -1) {
        return true;
      }
      return false;
    }

}
