import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {observable, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {VMSMessage} from "../models/VMSMessage";
import {MessageVMS} from "../models/messagevms";
import {Znakvmsb} from "../models/znakvmsb";
import {Znakvmsc} from "../models/znakvmsc";

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  constructor(private httpClient: HttpClient) { }

  messages: VMSMessage[] = [];
  messages_vms: MessageVMS[] = [];
  vmsb_list: Znakvmsb[] = [];
  vmsc_list: Znakvmsc[] = [];
  vmsc_messages: MessageVMS[] = [];
  vmsc_defined: VMSMessage[] = [];

  // pobierz listę zdefiniowanych treści
  getMessages(): Observable<VMSMessage[]> {

    let obsrvable =  new Observable<VMSMessage[]>((observer) => {
      if (this.messages.length == 0) {
        this.httpClient.get(
          'messages',
          {responseType: 'json'}

        ).pipe(map(val => val as {
          definied_messages:VMSMessage[], message_vms:MessageVMS[], vmsb_list:Znakvmsb[],
          vmsc_list:Znakvmsc[], vmsc_messages: MessageVMS[], vmsc_defined: VMSMessage[]

        })).subscribe(value => {
          this.messages = value.definied_messages;
          this.messages_vms = value.message_vms
          this.vmsb_list = value.vmsb_list;
          this.vmsc_list = value.vmsc_list;
          this.vmsc_messages = value.vmsc_messages;
          this.vmsc_defined = value.vmsc_defined;
          observer.next(this.messages);
        })
      } else {
        observer.next(this.messages);
      }

    })
    return obsrvable;
  }

  getVmsBList(): Observable<Znakvmsb[]> {
    let observable = new Observable<Znakvmsb[]>((observer)=>{
      if (this.messages.length > 0) {
        observer.next(this.vmsb_list)
      } else {
        this.getMessages().subscribe(value => {
          observer.next(this.vmsb_list)
        })
      }
    });
    return observable
  }

  getAssets(): Observable<string[]> {
    return this.httpClient.get('getassets', {responseType: 'json'}).pipe(map(value => value as string[]));
  }

  // pobierz wygenerowany obraz dla ustalonej treści znaku
  getPreview(text1: string, text2: string, img1: string, img2: string): Observable<string> {
    return this.httpClient.post(`messagepreview`, {
      responseType: 'json',
      params: {"recipe": {"text": [text1, text2], "sign": [img1, img2]}}
    }).
    pipe(map(val => {
      return val['path'] as string;
    }));
  }

  // zapisz nową treść znaku
  save(nazwa: string, path: string, text1: string, text2: string, img1: string, img2: string): Observable<boolean> {
    return this.httpClient.post(`messagesave`, {
      responseType: 'json',
      params: {"nazwa": nazwa, "path": path, "recipe": {"text": [text1, text2], "sign": [img1, img2]}}
    }).
    pipe(map(val => {
      // przy nastepnym rzadaniu trzeba odświerzyć messages z serwera
      this.messages = []
      return val['result'] as boolean;
    }));
  }

  // ustaw dany znak na danym VMS
  setVmsMessage(vmsId: number, messageId: number): Observable<boolean> {
    return this.httpClient.get(`setmessagevms`, {
      responseType: 'json',
      params: {"vms": (vmsId).toString(), "message": messageId.toString()}
    }).
    pipe(map(val => {
      this.messages = []
      return val['result'] as boolean;
    }));
  }

  getVmsMessage(vmsId: number): MessageVMS {
      for(let i=0; i < this.messages_vms.length; i++) {
        if (this.messages_vms[i].vms_id == vmsId ) {
          return this.messages_vms[i]
        }
      }
  }

  getMessageVmsById(id: number): MessageVMS {
    console.log('getMessageVmsById')
    for(let i=0; i < this.messages_vms.length; i++) {
      if (this.messages_vms[i].id == id ) {
        console.log('foind messegavms for id '+id);
        return this.messages_vms[i]
      }
    }
  }

  getVmsCList(): Observable<Znakvmsc[]> {
    let observable = new Observable<Znakvmsc[]>((observer)=>{
      if (this.messages.length > 0) {
        observer.next(this.vmsc_list)
      } else {
        console.log("messages jest puste, wez znaki")
        this.getMessages().subscribe(value => {
          console.log("messages jest puste, wez znaki")
          observer.next(this.vmsc_list)
        })
      }
    });
    return observable
  }

  getVmsCMessages(vmsId: number): MessageVMS[] {
    let list: MessageVMS[] = [];
    for(let i=0; i < this.vmsc_messages.length; i++) {
      if (this.vmsc_messages[i].vms_id == vmsId ) {
        list.push(this.vmsc_messages[i])
      }
    }
    return list
  }

  getVmsCMessage(id: number): VMSMessage {
    for(let i=0; i < this.vmsc_defined.length; i++) {
      if (this.vmsc_defined[i].id == id ) {
        return this.vmsc_defined[i]
      }
    }
  }



  setVmsCMessage(vms_id: number, strona: string, message_id: number): Observable<boolean> {
    return this.httpClient.get(`setvmscmessage`, {
      responseType: 'json',
      params: {"vms": (vms_id).toString(), 'side': strona ,"message": message_id.toString()}
    }).
    pipe(map(val => {
      this.messages = []
      return val['result'] as boolean;
    }));
    console.log("brak takiej tresci")
    console.log(this.vmsc_defined)
  }

  getMessageById(id:number): VMSMessage {

    for(let i=0;i<this.messages.length;i++) {
      if (this.messages[i].id == id) {
        return this.messages[i]
      }
    }
    return new VMSMessage();
  }

}
