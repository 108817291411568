import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {VMSMessage} from "../models/VMSMessage";
import {map} from "rxjs/operators";
import {MessageVMS} from "../models/messagevms";
import {Znakvmsb} from "../models/znakvmsb";
import {Znakvmsc} from "../models/znakvmsc";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Log} from "../models/log";

@Injectable({
  providedIn: 'root'
})
export class LogService {

  messages: Log[] = [];

  constructor(private httpClient: HttpClient) {

  }

  // pobierz listę zdefiniowanych treści
  getMessages(): Observable<Log[]> {

    return this.httpClient.get(
          'logmessages',
          {responseType: 'json'}

        ).pipe(map(val => val as Log[]));
  }

  save(user, messae) {

  }

}
