<mat-card *ngFor="let vms of vmsc_list">
  <mat-card-header>
    <mat-card-title>{{vms.description}}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-button-toggle-group [value]="current[vms.id]" name="{{vms.id}}">
      <mat-button-toggle [value]="messages[vms.id][0].id" (click)="selected(vms.id,'A', messages[vms.id][0].id)"> Przejdź <img src="{{messages[vms.id][0].path}}"></mat-button-toggle>
      <mat-button-toggle [value]="messages[vms.id][1].id" (click)="selected(vms.id,'B', messages[vms.id][1].id)"> Przejdź <img src="{{messages[vms.id][1].path}}"></mat-button-toggle>
      <mat-button-toggle [value]="messages[vms.id][2].id" (click)="selected(vms.id,'C', messages[vms.id][2].id)"> Przejdź <img src="{{messages[vms.id][2].path}}"></mat-button-toggle>
    </mat-button-toggle-group>
  </mat-card-content>
</mat-card>
