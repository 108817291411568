<mat-spinner *ngIf="showSpinner" class="login"></mat-spinner>
<mat-card *ngIf="showLogin" class="login">
  <mat-card-header>
    <mat-card-title>
      Logowanie
    </mat-card-title>
    <mat-card-subtitle>
    Podaj login i hasło, a następnie kliknij w przycisk ZALOGUJ
    </mat-card-subtitle>
  </mat-card-header>
  <form (submit)="logoj()" class="mat-card-form">
    <mat-card-content>
      <mat-form-field>
          <mat-label>Login</mat-label>
          <input matInput id="login" name="login" #loginveri="ngModel" type="text" minlength="6" maxlength="26" [(ngModel)]="login" required="required" class="form-controll"/>
      </mat-form-field>
      <div *ngIf="loginveri.invalid && (loginveri.dirty || loginveri.touched)"
           class="alert alert-danger">
        <div *ngIf="loginveri.errors.required">
          Proszę podać login
        </div>
      </div>
    </mat-card-content>
    <mat-card-content>
        <mat-form-field>
          <mat-label for="password">Hasło</mat-label>
          <input matInput id="password" name="password" [(ngModel)]="haslo" type="password" minlength="8" maxlength="26" required="required" class="form-control"/>
        </mat-form-field>
        <div *ngIf="veri" class="alert alert-danger">
            Użytkownik lub hasło nieprawidłowe
        </div>
    </mat-card-content>

    <mat-card-actions>
        <button mat-raised-button color="primary" type="submit" name="submit" class="mat-button">Zaloguj</button>
    </mat-card-actions>
  </form>



</mat-card>
