import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {observable, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {messagecb} from "../models/messagecb";
import {VMSMessage} from "../models/VMSMessage";
import {MessageVMS} from "../models/messagevms";
import {Znakvmsb} from "../models/znakvmsb";
import {Znakvmsc} from "../models/znakvmsc";

@Injectable({
  providedIn: 'root'
})
export class CbService {

  messages: messagecb[] = [];

  constructor(private httpClient: HttpClient) {

  }

  getMessages(): Observable<messagecb[]> {

    let obsrvable =  new Observable<messagecb[]>((observer) => {
      if (this.messages.length == 0) {
        this.httpClient.get(
          'messagescb',
          {responseType: 'json'}

        ).pipe(map(val => val as messagecb[])).subscribe(value => {
          this.messages = value;
          observer.next(this.messages);
        })
      } else {
        observer.next(this.messages);
      }

    })
    return obsrvable;
  }

  save(messagecb): Observable<boolean> {
    return this.httpClient.post(
          'cbsave',
          {messagecb}
        ).pipe(map(val => val['result'] as boolean))
  }

  getAudio(text: string): Observable<string> {
    return this.httpClient.get(
      'cbpreview', {
        responseType: 'json', params:{'text': text}}
        ).pipe(map(val => {
          return val['audio'] as string
    }));
  }
}
