import {Component, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MatStepper } from "@angular/material/stepper";
import {MatSelect, MatSelectChange} from "@angular/material/select";
import {MessagesService} from "../../../services/messages.service";
import {LogService} from "../../../services/log.service";

/**
 * @title Stepper overview
 */
@Component({
  selector: 'app-message-definition',
  templateUrl: 'app.message.definition.html',
  styleUrls: ['app.message.definition.css'],
})
export class AppMessageDefinitionComponent implements OnInit {
  @ViewChild("stepper", { static: false }) private stepper: MatStepper;
  isLinear = false;
  firstFormGroup: FormGroup;
  img1Step: FormGroup;
  img2Step: FormGroup;
  secondFormGroup: FormGroup;
  lastStep: FormGroup
  showImg1Step: boolean = false;
  showImg2Step: boolean = false;
  showMessageStep: boolean = true;
  displaySecondLine: boolean = false;
  previewFilePath: string = "";
  messagesService: MessagesService;

  line1Text: string = "";
  line2Text: string = "";
  image1Src: string = "";
  image2Src: string = "";
  messageName: string = "";
  img1src: string = "";
  img2src: string = "";
  assets: string[] = [];
  zapisano: boolean = false;
  logService: LogService;

  constructor(messagesService: MessagesService, logService: LogService,private _formBuilder: FormBuilder) {
    this.messagesService = messagesService;
    this.logService = logService;
  }

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.img1Step = this._formBuilder.group({
      img1Control: ['', Validators.required]
    });
    this.img2Step = this._formBuilder.group({
      img2Control: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });

    this.lastStep = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });

    this.messagesService.getAssets().subscribe(value => {
      this.assets = value
    })

  }

  formChanged(e) {
    if (e.value == '0') {
      this.showImg1Step = false;
      this.showImg2Step = false;
      this.showMessageStep = true;
    } else if (e.value == '1') {
      this.showImg1Step = true;
      this.showImg2Step = false;
      this.showMessageStep = true;
    } else if (e.value == '2') {
      this.showImg1Step = true;
      this.showImg2Step = true;
      this.showMessageStep = false;
      this.line1Text = "";
      this.line2Text = "";
    }
    this.zapisano = false;
  }

  numRowsChanged(event) {
    if (event.target.value == 2) {
      this.displaySecondLine = true;
    } else {
      this.displaySecondLine = false;
      this.line2Text = ""
    }
    this.zapisano = false;
  }

  image2selectionChange() {
    this.img2src = "/" + this.image2Src;
    this.zapisano = false;
  }

  image1selectionChange() {
    this.img1src = "/" + this.image1Src;
    this.zapisano = false;
  }

  nextStep() {
    setTimeout(()=>{
      if (this.stepper.selectedIndex == this.stepper.steps.length-1) {
        this.messagesService.getPreview(this.line1Text, this.line2Text, this.image1Src, this.image2Src).subscribe(
            str => {
              this.previewFilePath = str;
            });
        }
      }
    );
  }

  save() {
    this.messagesService.save(this.messageName, this.previewFilePath, this.line1Text, this.line2Text, this.image1Src, this.image2Src  ).subscribe(
      result => {
        if (result) {
          this.zapisano = true;
        }
      });
  }


}
