import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {map} from 'rxjs/operators';
import {Scheme} from "../models/scheme";
import {User} from "../models/user";

@Injectable({
  providedIn: 'root'
})
export class SchemeService {

  lista: Scheme[] = []

  constructor(private httpClient: HttpClient) {
  }

  getSchemes(): Observable<Scheme[]> {
    return this.httpClient.get('getschemas', {responseType: 'json'}).pipe(map(val => val as Scheme[]));
  }

}
