import {Component, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {CbService} from "../../../services/cb.service";
import {messagecb} from "../../../models/messagecb";
import {LogService} from "../../../services/log.service";

/**
 * @title Stepper overview
 */
@Component({
  selector: 'app-cb-definition',
  templateUrl: 'app.cb.definition.html',
  styleUrls: ['app.cb.definition.css'],
})
export class AppCbDefinitionComponent implements OnInit {

  cbService: CbService;
  cbName: string;
  cbMessage: string;
  cbPause: number = 90;
  cbRepeateCount: number = 30;
  cbEndDate: string = "";
  cbEndTime: string = "";
  cbRadioChannel: number = 19;
  audioSrc: string;

  actionResult: number = 0;
  saveBadgeColor: string = 'primary';
  saveBadgeHidden: boolean = true;
  saveBadgeText: string = 'ok';
  saveButtonDisabled: boolean = false;

  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;


  constructor(cbService: CbService, private _formBuilder: FormBuilder) {
    this.cbService = cbService;
  }

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      cbName: ['', Validators.required]
    });
    this.firstFormGroup.valueChanges.subscribe(value => { this.resetSave() })
    this.secondFormGroup = this._formBuilder.group({
      cbMessage: ['', Validators.required]
    });
    this.secondFormGroup.valueChanges.subscribe(value => { this.resetSave() })

    this.thirdFormGroup = this._formBuilder.group({
      cbPause: ['', Validators.required],
      cbRepeateCount: ['', Validators.required],
      cbEndDate: ['', Validators.required],
      cbEndTime: ['', Validators.required],
      cbRadioChannel: ['', Validators.required]
    });
    this.thirdFormGroup.valueChanges.subscribe(value => { this.resetSave() })

    this.fourthFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });

  }

  textToSpeach() {
   this.cbService.getAudio(this.cbMessage).subscribe(value => {
     this.audioSrc = "data:audio/mp3;base64," + value;
   });
  }

  resetSave() {
    this.saveBadgeHidden = true;
    this.actionResult = 0;
    this.saveButtonDisabled = false;
  }

  save() {
    this.saveButtonDisabled = true;

    let m = new messagecb()
    m.radioChannel = this.cbRadioChannel;
    m.message = this.cbName;
    m.repeatNumber = this.cbRepeateCount;
    m.removeTime = this.cbEndDate + " " + this.cbEndTime;
    m.pauseTime = this.cbPause;
    m.messageText = this.cbMessage;
    m.audioData = this.audioSrc;

    this.cbService.save(m).subscribe(value => {

      this.saveBadgeHidden = true;
      this.actionResult = 1;

      if (value == true) {
        this.saveBadgeText = "V";
        this.saveBadgeColor = 'primary';
        this.saveBadgeHidden = false;
        this.actionResult = 2;
      } else {
        this.saveBadgeText = "X";
        this.saveBadgeColor = 'warn';
        this.saveBadgeHidden = false;
        this.actionResult = -1;
        this.saveButtonDisabled = false;
      }
    });
  }


}
