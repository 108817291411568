<ul>
  <li>
    <span class="szrau-name">Urządzenie</span>
    <span class="szrau-value">{{device.description}}</span>
  </li>
  <li>
    <span class="szrau-name">Lokalizacja</span>
    <span class="szrau-value">{{device.roadpoint}}</span>
  </li>
  <li>
    <span class="szrau-name">Stan</span>
    <span class="szrau-value">{{device.state}}</span>
  </li>
  <li>
    <span class="szrau-name">Ostatnie sprawdzenie</span>
    <span class="szrau-value">{{device.last_check}}</span>
  </li>
</ul>
