import {Component, OnInit} from "@angular/core";
import {MessagesService} from "../../services/messages.service";
import * as L from 'leaflet';
import {Znakvmsb} from "../../models/znakvmsb";
import {hardwareitem} from "../../models/hardwareitem";
import {HardwareitemsService} from "../../services/hardwareitems.service";


class pozycjaNaMapie {
  icon1;
  icon2;
  icon3;
  popup;
  marker;
}

@Component({
  selector: 'app-map',
  styleUrls: ['app.map.css'],
  templateUrl: 'app.map.html',
})
export class AppMapComponent implements OnInit {

  public map: L.Map;
  public zoom: number;
  mapOptions: {};
  itemList: hardwareitem[] = [];
  mapList: pozycjaNaMapie[] = [];

  constructor(private hiService: HardwareitemsService) {

  }

  ngOnInit() {
    this.mapOptions = {
      minZoom: 1,
      maxZoom: 3,
      crs: L.CRS.Simple,
      closePopupOnClick: false,
      layers:[
        L.imageOverlay('static/js/assets/map.png', [[-200,0], [0, 857.25]])],
      center: [-100,425],
      zoom: 2,
      maxBounds: [[-400,-200],[200,1050]]
    };

  }

  onMapReady(map: L.Map) {
    this.map = map;
    this.zoom = map.getZoom();

    this.hiService.getItems().subscribe(value => {
      this.itemList = value;
      this.naniesPunkty();
    });
  }

  naniesPunkty() {
    this.itemList.forEach(value => {
      let icon1 = L.icon({iconUrl: value.map_icon, iconSize: [40,40]});
      let icon2 = L.icon({iconUrl: value.map_icon, iconSize: [30,30]});
      let icon3 = L.icon({iconUrl: value.map_icon, iconSize: [20,20]});

      let popup = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
      //TODO dodaj komponent danego typu obiektu
      popup.setContent(value.description);
      let marker = L.marker([value.map_position.x, value.map_position.y], {icon: icon2});
      marker.bindPopup(popup);
      marker.addTo(this.map);

      let p = new pozycjaNaMapie()
      p.icon1 = icon1;
      p.icon2 = icon2;
      p.icon3 = icon3;
      p.popup = popup;
      p.marker =  marker;
      this.mapList.push(p);
    });
  }

  zoomChanged(zoomLevel: number) {
    this.mapList.forEach(value => {
      if (zoomLevel == 1)
        value.marker.setIcon(value.icon1);
      if (zoomLevel == 2)
        value.marker.setIcon(value.icon2);
      if (zoomLevel == 3)
        value.marker.setIcon(value.icon3);
    });
  }

  // cbIcon1 = L.icon({iconUrl: 'assets/cb2.png', iconSize: [20,20]});
  // cbIcon2 = L.icon({iconUrl: 'assets/cb2.png', iconSize: [30,30]});
  // cbIcon3 = L.icon({iconUrl: 'assets/cb2.png', iconSize: [40,40]});
  // cctvIcon1 = L.icon({iconUrl: 'assets/cctv3.png', iconSize: [20,20]});
  // cctvIcon2 = L.icon({iconUrl: 'assets/cctv3.png', iconSize: [30,30]});
  // cctvIcon3 = L.icon({iconUrl: 'assets/cctv3.png', iconSize: [40,40]});
  // msIcon1 = L.icon({iconUrl: 'assets/ms.png', iconSize: [20,20]});
  // msIcon2 = L.icon({iconUrl: 'assets/ms.png', iconSize: [30,30]});
  // msIcon3 = L.icon({iconUrl: 'assets/ms.png', iconSize: [40,40]});
  // tcIcon1 = L.icon({iconUrl: 'assets/tc3.png', iconSize: [20,20]});
  // tcIcon2 = L.icon({iconUrl: 'assets/tc3.png', iconSize: [30,30]});
  // tcIcon3 = L.icon({iconUrl: 'assets/tc3.png', iconSize: [40,40]});
  // vmsbIcon1 = L.icon({iconUrl: 'assets/vmsb3.png', iconSize: [20,20]});
  // vmsbIcon2 = L.icon({iconUrl: 'assets/vmsb3.png', iconSize: [30,30]});
  // vmsbIcon3 = L.icon({iconUrl: 'assets/vmsb3.png', iconSize: [40,40]});
  // vmscIcon1 = L.icon({iconUrl: 'assets/vmsc.png', iconSize: [20,20]});
  // vmscIcon2 = L.icon({iconUrl: 'assets/vmsc.png', iconSize: [30,30]});
  // vmscIcon3 = L.icon({iconUrl: 'assets/vmsc.png', iconSize: [40,40]});
  // vmspIcon1 = L.icon({iconUrl: 'assets/vmsp.png', iconSize: [20, 20]});
  // vmspIcon2 = L.icon({iconUrl: 'assets/vmsp.png', iconSize: [30, 30]});
  // vmspIcon3 = L.icon({iconUrl: 'assets/vmsp.png', iconSize: [40, 40]});
  //
  // popcb01 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popcctv01 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popcctv02 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popcctv03 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popcctv04 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popcctv05 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popcctv06 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popcctv07 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popcctv08 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popcctv09 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popcctv10 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popcctv11 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popcctv12 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popcctv13 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popms01 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // poptc01 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // poptc02 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // poptc03 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // poptc04 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // poptcp01 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // poptcp02 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // poptcp03 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // poptcp04 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popvmsb01 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popvmsb02 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popvmsb03 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popvmsb04 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popvmsb05 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popvmsb06 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popvmsb07 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popvmsb08 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popvmsb09 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popvmsb10 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popvmsc01 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popvmsc02 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popvmsc03 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popvmsc04 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popvmsc05 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popvmsc06 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popvmsc07 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popvmsc08 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popvmsc09 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popvmsc10 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popvmsc11 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popvmsc12 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popvmsc13 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popvmsc14 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popvmsc15 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popvmsp01 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  // popvmsp02 = L.popup({autoClose: false, closeOnClick: false, autoPan: false});
  //
  //
  // cb01 = L.marker([-80, 425], {icon: this.cbIcon2});
  // cctv01 = L.marker([-150, 65], {icon: this.cctvIcon2});
  // cctv02 = L.marker([-140, 140], {icon: this.cctvIcon2});
  // cctv03 = L.marker([-130, 210], {icon: this.cctvIcon2});
  // cctv04 = L.marker([-120, 260], {icon: this.cctvIcon2});
  // cctv05 = L.marker([-40, 285], {icon: this.cctvIcon2});
  // cctv06 = L.marker([-65, 363], {icon: this.cctvIcon2});
  // cctv07 = L.marker([-42, 425], {icon: this.cctvIcon2});
  // cctv08 = L.marker([-60, 475], {icon: this.cctvIcon2});
  // cctv09 = L.marker([-70, 545], {icon: this.cctvIcon2});
  // cctv10 = L.marker([-80, 610], {icon: this.cctvIcon2});
  // cctv11 = L.marker([-90, 671], {icon: this.cctvIcon2});
  // cctv12 = L.marker([-110, 740], {icon: this.cctvIcon2});
  // cctv13 = L.marker([-115, 780], {icon: this.cctvIcon2});
  // ms01 = L.marker([-80, 475], {icon: this.msIcon2});
  // tc01 = L.marker([-170, 65], {icon: this.tcIcon2});
  // tc02 = L.marker([-95, 65], {icon: this.tcIcon2});
  // tc03 = L.marker([-115, 805], {icon: this.tcIcon2});
  // tc04 = L.marker([-95, 805], {icon: this.tcIcon2});
  // tcp01 = L.marker([-115, 245], {icon: this.tcIcon2});
  // tcp02 = L.marker([-105, 260], {icon: this.tcIcon2});
  // tcp03 = L.marker([-80, 260], {icon: this.tcIcon2});
  // tcp04 = L.marker([-90, 245], {icon: this.tcIcon2});
  // vmsb01 = L.marker([-195, 65], {icon: this.vmsbIcon2});
  // vmsb02 = L.marker([-120, 65], {icon: this.vmsbIcon2});
  // vmsb03 = L.marker([-70, 315], {icon: this.vmsbIcon2});
  // vmsb04 = L.marker([-50, 315], {icon: this.vmsbIcon2});
  // vmsb05 = L.marker([-60, 425], {icon: this.vmsbIcon2});
  // vmsb06 = L.marker([-20, 425], {icon: this.vmsbIcon2});
  // vmsb07 = L.marker([-100, 610], {icon: this.vmsbIcon2});
  // vmsb08 = L.marker([-60, 610], {icon: this.vmsbIcon2});
  // vmsb09 = L.marker([-80, 805], {icon: this.vmsbIcon2});
  // vmsb10 = L.marker([-130, 805], {icon: this.vmsbIcon2});
  // vmsc01 = L.marker([-65, 335], {icon: this.vmscIcon2});
  // vmsc02 = L.marker([-65, 350], {icon: this.vmscIcon2});
  // vmsc03 = L.marker([-65, 377], {icon: this.vmscIcon2});
  // vmsc04 = L.marker([-42, 365], {icon: this.vmscIcon2});
  // vmsc05 = L.marker([-42, 382], {icon: this.vmscIcon2});
  // vmsc06 = L.marker([-42, 400], {icon: this.vmscIcon2});
  // vmsc07 = L.marker([-83, 632], {icon: this.vmscIcon2});
  // vmsc08 = L.marker([-85, 650], {icon: this.vmscIcon2});
  // vmsc09 = L.marker([-95, 660], {icon: this.vmscIcon2});
  // vmsc10 = L.marker([-70, 668], {icon: this.vmscIcon2});
  // vmsc11 = L.marker([-75, 680], {icon: this.vmscIcon2});
  // vmsc12 = L.marker([-80, 695], {icon: this.vmscIcon2});
  // vmsc14 = L.marker([-135, 780], {icon: this.vmscIcon2});
  // vmsc15 = L.marker([-120, 825], {icon: this.vmscIcon2});
  // vmsp01 = L.marker([-150, 210], {icon: this.vmspIcon2});
  // vmsp02 = L.marker([-60, 285], {icon: this.vmspIcon2});
  //
  //
  //
  // onMapReady(map: L.Map) {
  //   this.map = map;
  //   this.zoom = map.getZoom();
  //
  //
  //
  //   this.cb01.bindPopup(this.popcb01.setContent('cb01')).addTo(map);
  //   this.cctv01.bindPopup(this.popcctv01.setContent('cctv01')).addTo(map);
  //   this.cctv02.bindPopup(this.popcctv02.setContent('cctv02')).addTo(map);
  //   this.cctv03.bindPopup(this.popcctv03.setContent('cctv03')).addTo(map);
  //   this.cctv04.bindPopup(this.popcctv04.setContent('cctv04')).addTo(map);
  //   this.cctv05.bindPopup(this.popcctv05.setContent('cctv05')).addTo(map);
  //   this.cctv06.bindPopup(this.popcctv06.setContent('cctv06')).addTo(map);
  //   this.cctv07.bindPopup(this.popcctv07.setContent('cctv07')).addTo(map);
  //   this.cctv08.bindPopup(this.popcctv08.setContent('cctv08')).addTo(map);
  //   this.cctv09.bindPopup(this.popcctv09.setContent('cctv09')).addTo(map);
  //   this.cctv10.bindPopup(this.popcctv10.setContent('cctv10')).addTo(map);
  //   this.cctv11.bindPopup(this.popcctv11.setContent('cctv11')).addTo(map);
  //   this.cctv12.bindPopup(this.popcctv12.setContent('cctv12')).addTo(map);
  //   this.cctv13.bindPopup(this.popcctv13.setContent('cctv13')).addTo(map);
  //   this.ms01.bindPopup(this.popms01.setContent('ms01')).addTo(map);
  //   this.tc01.bindPopup(this.poptc01.setContent('tc01')).addTo(map);
  //   this.tc02.bindPopup(this.poptc02.setContent('tc02')).addTo(map);
  //   this.tc03.bindPopup(this.poptc03.setContent('tc03')).addTo(map);
  //   this.tc04.bindPopup(this.poptc04.setContent('tc04')).addTo(map);
  //   this.tcp01.bindPopup(this.poptcp01.setContent('tcp01')).addTo(map);
  //   this.tcp02.bindPopup(this.poptcp02.setContent('tcp02')).addTo(map);
  //   this.tcp03.bindPopup(this.poptcp03.setContent('tcp03')).addTo(map);
  //   this.tcp04.bindPopup(this.poptcp04.setContent('tcp04')).addTo(map);
  //   this.vmsb01.bindPopup(this.popvmsb01.setContent('vmsb01')).addTo(map);
  //   this.vmsb02.bindPopup(this.popvmsb02.setContent('vmsb02')).addTo(map);
  //   this.vmsb03.bindPopup(this.popvmsb03.setContent('vmsb03')).addTo(map);
  //   this.vmsb04.bindPopup(this.popvmsb04.setContent('vmsb04')).addTo(map);
  //   this.vmsb05.bindPopup(this.popvmsb05.setContent('vmsb05')).addTo(map);
  //   this.vmsb06.bindPopup(this.popvmsb06.setContent('vmsb06')).addTo(map);
  //   this.vmsb07.bindPopup(this.popvmsb07.setContent('vmsb07')).addTo(map);
  //   this.vmsb08.bindPopup(this.popvmsb08.setContent('vmsb08')).addTo(map);
  //   this.vmsb09.bindPopup(this.popvmsb09.setContent('vmsb09')).addTo(map);
  //   this.vmsb10.bindPopup(this.popvmsb10.setContent('vmsb10')).addTo(map);
  //   this.vmsc01.bindPopup(this.popvmsc01.setContent('vmsc01')).addTo(map);
  //   this.vmsc02.bindPopup(this.popvmsc02.setContent('vmsc02')).addTo(map);
  //   this.vmsc03.bindPopup(this.popvmsc03.setContent('vmsc03')).addTo(map);
  //   this.vmsc04.bindPopup(this.popvmsc04.setContent('vmsc04')).addTo(map);
  //   this.vmsc05.bindPopup(this.popvmsc05.setContent('vmsc05')).addTo(map);
  //   this.vmsc06.bindPopup(this.popvmsc06.setContent('vmsc06')).addTo(map);
  //   this.vmsc07.bindPopup(this.popvmsc07.setContent('vmsc07')).addTo(map);
  //   this.vmsc08.bindPopup(this.popvmsc08.setContent('vmsc08')).addTo(map);
  //   this.vmsc09.bindPopup(this.popvmsc09.setContent('vmsc09')).addTo(map);
  //   this.vmsc10.bindPopup(this.popvmsc10.setContent('vmsc10')).addTo(map);
  //   this.vmsc11.bindPopup(this.popvmsc11.setContent('vmsc11')).addTo(map);
  //   this.vmsc12.bindPopup(this.popvmsc12.setContent('vmsc12')).addTo(map);
  //   this.vmsc14.bindPopup(this.popvmsc14.setContent('vmsc14')).addTo(map);
  //   this.vmsc15.bindPopup(this.popvmsc15.setContent('vmsc15')).addTo(map);
  //   this.vmsp01.bindPopup(this.popvmsp01.setContent('vmsp01')).addTo(map);
  //   this.vmsp02.bindPopup(this.popvmsp02.setContent('vmsp02')).addTo(map);
  //
  // }

  onMapZoomEnd(e: L.ZoomAnimEvent) {
    this.zoom = e.target.getZoom();
    console.log(this.zoom);
    this.zoomChanged(this.zoom);
    // if (this.zoom === 1){
    //   this.cb01.setIcon(this.cbIcon1).setLatLng([-80, 425]);
    //   this.cctv01.setIcon(this.cctvIcon1);
    //   this.cctv02.setIcon(this.cctvIcon1);
    //   this.cctv03.setIcon(this.cctvIcon1);
    //   this.cctv04.setIcon(this.cctvIcon1).setLatLng([-120, 260]);
    //   this.cctv05.setIcon(this.cctvIcon1).setLatLng([-40, 285]);
    //   this.cctv06.setIcon(this.cctvIcon1);
    //   this.cctv07.setIcon(this.cctvIcon1);
    //   this.cctv08.setIcon(this.cctvIcon1);
    //   this.cctv09.setIcon(this.cctvIcon1);
    //   this.cctv10.setIcon(this.cctvIcon1);
    //   this.cctv11.setIcon(this.cctvIcon1);
    //   this.cctv12.setIcon(this.cctvIcon1);
    //   this.cctv13.setIcon(this.cctvIcon1);
    //   this.ms01.setIcon(this.msIcon1).setLatLng([-80, 475]);
    //   this.tc01.setIcon(this.tcIcon1).setLatLng([-170, 65]);
    //   this.tc02.setIcon(this.tcIcon1).setLatLng([-95, 65]);
    //   this.tc03.setIcon(this.tcIcon1);
    //   this.tc04.setIcon(this.tcIcon1);
    //   this.tcp01.setIcon(this.tcIcon1);
    //   this.tcp02.setIcon(this.tcIcon1);
    //   this.tcp03.setIcon(this.tcIcon1);
    //   this.tcp04.setIcon(this.tcIcon1);
    //   this.vmsb01.setIcon(this.vmsbIcon1).setLatLng([-195, 65]);
    //   this.vmsb02.setIcon(this.vmsbIcon1);
    //   this.vmsb03.setIcon(this.vmsbIcon1);
    //   this.vmsb04.setIcon(this.vmsbIcon1);
    //   this.vmsb05.setIcon(this.vmsbIcon1);
    //   this.vmsb06.setIcon(this.vmsbIcon1).setLatLng([-20, 425]);
    //   this.vmsb07.setIcon(this.vmsbIcon1).setLatLng([-100, 610]);
    //   this.vmsb08.setIcon(this.vmsbIcon1);
    //   this.vmsb09.setIcon(this.vmsbIcon1).setLatLng([-75, 805]);
    //   this.vmsb10.setIcon(this.vmsbIcon1).setLatLng([-135, 805]);
    //   this.vmsc01.setIcon(this.vmscIcon1);
    //   this.vmsc02.setIcon(this.vmscIcon1);
    //   this.vmsc03.setIcon(this.vmscIcon1);
    //   this.vmsc04.setIcon(this.vmscIcon1);
    //   this.vmsc05.setIcon(this.vmscIcon1);
    //   this.vmsc06.setIcon(this.vmscIcon1);
    //   this.vmsc07.setIcon(this.vmscIcon1);
    //   this.vmsc08.setIcon(this.vmscIcon1);
    //   this.vmsc09.setIcon(this.vmscIcon1);
    //   this.vmsc10.setIcon(this.vmscIcon1);
    //   this.vmsc11.setIcon(this.vmscIcon1);
    //   this.vmsc12.setIcon(this.vmscIcon1);
    //   this.vmsc14.setIcon(this.vmscIcon1).setLatLng([-135, 780]);
    //   this.vmsc15.setIcon(this.vmscIcon1);
    //   this.vmsp01.setIcon(this.vmspIcon1).setLatLng([-150, 210]);
    //   this.vmsp02.setIcon(this.vmspIcon1);
    // }
    // if (this.zoom === 2){
    //   this.cb01.setIcon(this.cbIcon2).setLatLng([-75, 425]);
    //   this.cctv01.setIcon(this.cctvIcon2);
    //   this.cctv02.setIcon(this.cctvIcon2);
    //   this.cctv03.setIcon(this.cctvIcon2);
    //   this.cctv04.setIcon(this.cctvIcon2).setLatLng([-115, 260]);
    //   this.cctv05.setIcon(this.cctvIcon2).setLatLng([-45, 285]);
    //   this.cctv06.setIcon(this.cctvIcon2);
    //   this.cctv07.setIcon(this.cctvIcon2);
    //   this.cctv08.setIcon(this.cctvIcon2);
    //   this.cctv09.setIcon(this.cctvIcon2);
    //   this.cctv10.setIcon(this.cctvIcon2);
    //   this.cctv11.setIcon(this.cctvIcon2);
    //   this.cctv12.setIcon(this.cctvIcon2);
    //   this.cctv13.setIcon(this.cctvIcon2);
    //   this.ms01.setIcon(this.msIcon2).setLatLng([-75, 475]);
    //   this.tc01.setIcon(this.tcIcon2).setLatLng([-165, 65]);
    //   this.tc02.setIcon(this.tcIcon2).setLatLng([-100, 65]);
    //   this.tc03.setIcon(this.tcIcon2);
    //   this.tc04.setIcon(this.tcIcon2);
    //   this.tcp01.setIcon(this.tcIcon2);
    //   this.tcp02.setIcon(this.tcIcon2);
    //   this.tcp03.setIcon(this.tcIcon2);
    //   this.tcp04.setIcon(this.tcIcon2);
    //   this.vmsb01.setIcon(this.vmsbIcon2).setLatLng([-185, 65]);
    //   this.vmsb02.setIcon(this.vmsbIcon2);
    //   this.vmsb03.setIcon(this.vmsbIcon2);
    //   this.vmsb04.setIcon(this.vmsbIcon2);
    //   this.vmsb05.setIcon(this.vmsbIcon2);
    //   this.vmsb06.setIcon(this.vmsbIcon2).setLatLng([-25, 425]);
    //   this.vmsb07.setIcon(this.vmsbIcon2).setLatLng([-95, 610]);
    //   this.vmsb08.setIcon(this.vmsbIcon2);
    //   this.vmsb09.setIcon(this.vmsbIcon2).setLatLng([-80, 805]);
    //   this.vmsb10.setIcon(this.vmsbIcon2).setLatLng([-130, 805]);
    //   this.vmsc01.setIcon(this.vmscIcon2);
    //   this.vmsc02.setIcon(this.vmscIcon2);
    //   this.vmsc03.setIcon(this.vmscIcon2);
    //   this.vmsc04.setIcon(this.vmscIcon2);
    //   this.vmsc05.setIcon(this.vmscIcon2);
    //   this.vmsc06.setIcon(this.vmscIcon2);
    //   this.vmsc07.setIcon(this.vmscIcon2);
    //   this.vmsc08.setIcon(this.vmscIcon2);
    //   this.vmsc09.setIcon(this.vmscIcon2);
    //   this.vmsc10.setIcon(this.vmscIcon2);
    //   this.vmsc11.setIcon(this.vmscIcon2);
    //   this.vmsc12.setIcon(this.vmscIcon2);
    //   this.vmsc14.setIcon(this.vmscIcon2).setLatLng([-130, 780]);
    //   this.vmsc15.setIcon(this.vmscIcon2);
    //   this.vmsp01.setIcon(this.vmspIcon2).setLatLng([-145, 210]);
    //   this.vmsp02.setIcon(this.vmspIcon2);
    // }
    // if (this.zoom === 3){
    //   this.cb01.setIcon(this.cbIcon3).setLatLng([-70, 425]);
    //   this.cctv01.setIcon(this.cctvIcon3);
    //   this.cctv02.setIcon(this.cctvIcon3);
    //   this.cctv03.setIcon(this.cctvIcon3);
    //   this.cctv04.setIcon(this.cctvIcon3).setLatLng([-110, 260]);
    //   this.cctv05.setIcon(this.cctvIcon3).setLatLng([-50, 285]);
    //   this.cctv06.setIcon(this.cctvIcon3);
    //   this.cctv07.setIcon(this.cctvIcon3);
    //   this.cctv08.setIcon(this.cctvIcon3);
    //   this.cctv09.setIcon(this.cctvIcon3);
    //   this.cctv10.setIcon(this.cctvIcon3);
    //   this.cctv11.setIcon(this.cctvIcon3);
    //   this.cctv12.setIcon(this.cctvIcon3);
    //   this.cctv13.setIcon(this.cctvIcon3);
    //   this.ms01.setIcon(this.msIcon3).setLatLng([-70, 475]);
    //   this.tc01.setIcon(this.tcIcon3).setLatLng([-160, 65]);
    //   this.tc02.setIcon(this.tcIcon3).setLatLng([-105, 65]);
    //   this.tc03.setIcon(this.tcIcon3);
    //   this.tc04.setIcon(this.tcIcon3);
    //   this.tcp01.setIcon(this.tcIcon3);
    //   this.tcp02.setIcon(this.tcIcon3);
    //   this.tcp03.setIcon(this.tcIcon3);
    //   this.tcp04.setIcon(this.tcIcon3);
    //   this.vmsb01.setIcon(this.vmsbIcon3).setLatLng([-175, 65]);
    //   this.vmsb02.setIcon(this.vmsbIcon3);
    //   this.vmsb03.setIcon(this.vmsbIcon3);
    //   this.vmsb04.setIcon(this.vmsbIcon3);
    //   this.vmsb05.setIcon(this.vmsbIcon3);
    //   this.vmsb06.setIcon(this.vmsbIcon3).setLatLng([-30, 425]);
    //   this.vmsb07.setIcon(this.vmsbIcon3).setLatLng([-90, 610]);
    //   this.vmsb08.setIcon(this.vmsbIcon3);
    //   this.vmsb09.setIcon(this.vmsbIcon3).setLatLng([-85, 805]);
    //   this.vmsb10.setIcon(this.vmsbIcon3).setLatLng([-125, 805]);
    //   this.vmsc01.setIcon(this.vmscIcon3);
    //   this.vmsc02.setIcon(this.vmscIcon3);
    //   this.vmsc03.setIcon(this.vmscIcon3);
    //   this.vmsc04.setIcon(this.vmscIcon3);
    //   this.vmsc05.setIcon(this.vmscIcon3);
    //   this.vmsc06.setIcon(this.vmscIcon3);
    //   this.vmsc07.setIcon(this.vmscIcon3);
    //   this.vmsc08.setIcon(this.vmscIcon3);
    //   this.vmsc09.setIcon(this.vmscIcon3);
    //   this.vmsc10.setIcon(this.vmscIcon3);
    //   this.vmsc11.setIcon(this.vmscIcon3);
    //   this.vmsc12.setIcon(this.vmscIcon3);
    //   this.vmsc14.setIcon(this.vmscIcon3).setLatLng([-125, 780]);
    //   this.vmsc15.setIcon(this.vmscIcon3);
    //   this.vmsp01.setIcon(this.vmspIcon3).setLatLng([-140, 210]);
    //   this.vmsp02.setIcon(this.vmspIcon3);
    // }
  }
}
