import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule} from "@angular/router";
import { AppTrafficTabsComponent} from "./components/traffic/app.traffic.tabs";
import {AppUserAddComponent} from "./components/user/app.user.add";
import {AppEventsListComponent} from "./components/events/app.events.list";
import {AppMessageStateComponent} from "./components/message/state/app.message.state";
import {AppMessageDefinitionComponent} from "./components/message/definition/app.message.definition";
import {ListaComponent} from "./lista"
import {AppMeteoListComponent} from "./components/meteo/app.meteo.list";
import {AppCbDefinitionComponent} from "./components/cb/definition/app.cb.definition";
import {AppCbStateComponent} from "./components/cb/state/app.cb.state";
import {AppDetourStateComponent} from "./components/detour/app.detour.state";
import {AppLogStateComponent} from "./components/log/app.log.state";
import {AppMopStateComponent} from "./components/mop/app.mop.state";
import {AppMessageSchemeComponent} from "./components/message/scheme/app.message.scheme";
import {AppMessageStatesetComponent} from "./components/message/stateset/app.message.stateset";
import {AppMapComponent} from "./components/map/app.map";

const routes: Routes = [
  {path: 'traffic', component: AppTrafficTabsComponent},
  {path: 'user', component: AppUserAddComponent},
  {path: 'events', component: AppEventsListComponent},
  {path: 'message', component: AppMessageStateComponent},
  {path: 'messageset', component: AppMessageStatesetComponent},
  {path: 'messagedef', component: AppMessageDefinitionComponent},
  {path: 'userlist', component: ListaComponent},
  {path: 'meteo', component: AppMeteoListComponent},
  {path: 'cbdef', component: AppCbDefinitionComponent},
  {path: 'cb', component: AppCbStateComponent},
  {path: 'vmsc', component: AppDetourStateComponent},
  {path: 'log', component: AppLogStateComponent},
  {path: 'mop', component: AppMopStateComponent},
  {path: 'messagescheme', component: AppMessageSchemeComponent},
  {path: 'map', component: AppMapComponent}
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
