<mat-vertical-stepper [linear]="isLinear" #stepper>

  <mat-step [stepControl]="firstFormGroup">
    <ng-template matStepLabel>Nazwa komunikatu</ng-template>
    <form [formGroup]="firstFormGroup">
      <mat-form-field>
        <input matInput formControlName="cbName" placeholder="nazwa komunikatu" required [(ngModel)]="cbName" name="cbName" />
      </mat-form-field>
    </form>
    <div>
      <button mat-button matStepperNext>Dalej</button>
    </div>
  </mat-step>


  <mat-step [stepControl]="secondFormGroup">
    <ng-template matStepLabel>Treść komunikatu</ng-template>
    <form [formGroup]="secondFormGroup">
      <mat-form-field>
        <textarea matInput placeholder="treść" [(ngModel)]="cbMessage" required name="cbMessage" formControlName="cbMessage"></textarea>
      </mat-form-field>
    </form>
    <div>
      <button mat-raised color="primary" (click)="textToSpeach()">Przygotuj odsłuch</button>
    </div>
    <div>
      <audio *ngIf="audioSrc" controls autobuffer="autobuffer"  src="{{audioSrc}}"></audio>
    </div>
    <div>
      <button mat-button matStepperPrevious>Wróć</button>
      <button mat-button matStepperNext>Dalej</button>
    </div>
  </mat-step>


  <mat-step [stepControl]="thirdFormGroup">
    <ng-template matStepLabel>Parametry nadawania</ng-template>
    <form [formGroup]="thirdFormGroup">
      <div>
        <mat-form-field>
          <label>Odstęp między nadawaniem komunikatu</label>
          <input [(ngModel)]="cbPause" matInput type=number value="90" min="30" max="300" required formControlName="cbPause" name="cbPause"/>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <label>Ilość powtórzeń - 0 oznacza aż do czasu zakończenia</label>
          <input [(ngModel)]="cbRepeateCount" matInput type=number value="30" min="0" max="99999" required formControlName="cbRepeateCount" name="cbRepeateCount"/>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <label>Data zakończenia</label>
          <input [(ngModel)]="cbEndDate" matInput type="date" formControlName="cbEndDate" required name="cbEndDate"/>
        </mat-form-field>

        <mat-form-field>
          <label>Godzina zakończenia</label>
          <input [(ngModel)]="cbEndTime" matInput type="time" formControlName="cbEndTime" required name="cbEndTime"/>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <label>Kanał nadawania</label>
          <input [(ngModel)]="cbRadioChannel" matInput type=number value="19" min="1" max="40" formControlName="cbRadioChannel" required name="cbRadioChannel"/>
        </mat-form-field>
      </div>
    </form>
    <div>
      <button mat-button matStepperPrevious>Wróć</button>
      <button mat-button matStepperNext>Dalej</button>
    </div>
  </mat-step>

  <mat-step [stepControl]="fourthFormGroup">

    <ul class="duors-summary">
      <li><span class="duors-var-name">Nazwa</span><span class="duors-var-value">{{cbName}}</span></li>
      <li><span class="duors-var-name">Treść</span><span class="duors-var-value">{{cbMessage}}</span></li>
      <li><span class="duors-var-name">Ilość powtórzeń</span><span class="duors-var-value">{{cbRepeateCount}}</span></li>
      <li><span class="duors-var-name">Odstęp</span><span class="duors-var-value">{{cbPause}}</span></li>
      <li><span class="duors-var-name">Czas zakończenia</span><span class="duors-var-value">{{cbEndDate}} {{cbEndTime}}</span></li>
      <li><span class="duors-var-name">Kanał</span><span class="duors-var-value">{{cbRadioChannel}}</span></li>
    </ul>

    <div>
      <button mat-button matStepperPrevious>Wróć</button>
      <button mat-raised-button color="primary" (click)="save()" [disabled]="saveButtonDisabled" [matBadge]="saveBadgeText"[matBadgeHidden]="saveBadgeHidden" [matBadgeColor]="saveBadgeColor">
        <span *ngIf="actionResult == 0">Zapisz</span>
        <span *ngIf="actionResult == 1">Zapisuję...</span>
        <span *ngIf="actionResult == 2">Zapisano !</span>
        <span *ngIf="actionResult == -1">Spróbuj ponownie</span>
      </button>
    </div>
  </mat-step>
</mat-vertical-stepper>
