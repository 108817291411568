import {Component, OnInit, ViewChild} from '@angular/core';
import {User} from "./models/user";
import {UserService} from "./services/user.service";


@Component({
  selector: 'app-component',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.css'],
})
export class AppComponent implements OnInit {
  showFiller = true;

  currentUser: User = new User();

  userService: UserService;

  constructor(userService: UserService) {
    this.userService = userService;
  }

  ngOnInit() {

  }

  userLogin(user: User) {
    this.currentUser = user;
  }

  logout() {
    this.userService.logout().subscribe(value => {
      this.currentUser = null;
    });

  }

}
