<h1 mat-dialog-title>Wybór treści znaku</h1>
<mat-spinner *ngIf="spinnerVisible==true"></mat-spinner>
<div *ngIf="spinnerVisible==false" mat-dialog-content>
  <mat-button-toggle-group #selectType="matButtonToggleGroup" aria-label="Select single">
    <mat-button-toggle checked='checked' id="single" value="single">Treść pojedyncza</mat-button-toggle>
<!--    <mat-button-toggle id="multi" value="multi">Wiele treści</mat-button-toggle>-->
    <mat-button-toggle id="scheme" value="scheme">Schemat</mat-button-toggle>
  </mat-button-toggle-group>
  <div *ngIf="selectType.value=='single'">
    <h2>Wybierz treść znaku z listy</h2>
    <form [formGroup]="selectSingle">
      <mat-button-toggle-group formControlName="messageSingle" multiple="false" aria-label="Select Multi">
        <ul class="messages-list">
          <li *ngFor="let m of messages"><mat-button-toggle value="{{m.id}}"><img src="{{m.path}}"></mat-button-toggle></li>
        </ul>
      </mat-button-toggle-group>
    </form>
    <div>
      <button mat-button (click)="onCloseClick()">Zamknij</button>
      <button mat-button (click)="saveSingle()" type="button">Zapisz</button>
    </div>
  </div>
  <mat-horizontal-stepper *ngIf="selectType.value=='multi'">
    <mat-step [stepControl]="selectMulti">
      <ng-template matStepLabel>Wiele treści</ng-template>
      <form [formGroup]="selectMulti">
        <mat-button-toggle-group formControlName="messageMulti" multiple="true" aria-label="Select Scheme">
          <ul class="messages-list">
            <li *ngFor="let m of messages"><mat-button-toggle value="{{m.id}}"><img src="{{m.path}}"></mat-button-toggle></li>
          </ul>
        </mat-button-toggle-group>
      </form>
      <div>
        <button mat-button (click)="onCloseClick()">Zamknij</button>
        <button mat-button matStepperPrevious type="button">Wstecz</button>
        <button mat-button matStepperNext type="button">Dalej</button>
      </div>
    </mat-step>
    <mat-step [stepControl]="selectTime">
      <form [formGroup]="selectTime">
        <h3>Częstotliwość zmiany (sekundy)</h3>
        <mat-slider formControlName="multiTime" step="1" min="1" max="5" value="3" thumbLabel tickInterval="1"></mat-slider>
      </form>
      <div>
        <button mat-button (click)="onCloseClick()">Zamknij</button>
        <button mat-button matStepperPrevious type="button">Wstecz</button>
        <button mat-button (click)="saveMulti()" type="button">Zapisz</button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
  <mat-horizontal-stepper *ngIf="selectType.value=='scheme'">
    <mat-step [stepControl]="selectScheme">
      <ng-template matStepLabel>Schemat</ng-template>
      <form [formGroup]="selectScheme">
        <h2>Wybierz schemat</h2>
        <mat-button-toggle-group formControlName="messageScheme" multiple="true" aria-label="Select Scheme">
          <ul class='messages-list'>
            <li *ngFor="let m of schemes">
              <mat-button-toggle value="{{m.id}}">{{m.name}}</mat-button-toggle></li>
          </ul>
        </mat-button-toggle-group>
      </form>
      <div>
        <button mat-button (click)="onCloseClick()">Zamknij</button>
        <button mat-button matStepperNext type="button">Dalej</button>
      </div>
    </mat-step>
<!--    <mat-step [stepControl]="selectSchemeTime">-->
<!--      <form [formGroup]="selectSchemeTime">-->
<!--        <div *ngIf="isMany()">-->
<!--          <h3>Częstotliwość zmiany (sekundy)</h3>-->
<!--          <mat-slider formControlName="schemeTime" step="1" min="1" max="5" value="3" thumbLabel tickInterval="1"></mat-slider>-->
<!--        </div>-->
<!--      </form>-->
<!--      <div>-->
<!--        <button mat-button (click)="onCloseClick()">Zamknij</button>-->
<!--        <button mat-button matStepperPrevious type  ="button">Wstecz</button>-->
<!--        <button mat-button matStepperNext type="button">Dalej</button>-->
<!--      </div>-->
<!--    </mat-step>-->
    <mat-step [stepControl]="selectSchemeVMS">
      <h2>Wybierz znaki do których zastosować schemat</h2>
      <form [formGroup]="selectSchemeVMS">
        <mat-button-toggle-group formControlName="schemeVMS" name="selectSigns" multiple="true" aria-label="Select Scheme">
          <ul class="messages-list">
            <li *ngFor="let m of vmsblist"><mat-button-toggle value="{{m.id}}">{{m.description}}</mat-button-toggle></li>
          </ul>
        </mat-button-toggle-group>
        <div>
          <button mat-button (click)="onCloseClick()">Zamknij</button>
          <button mat-button matStepperPrevious type="button">Wstecz</button>
          <button mat-button matStepperNext type="button">Dalej</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="selectSchemeLocation">
      <form [formGroup]="selectSchemeLocation">
        <div>
          <h2>Miejsce wydarzenia</h2>
          <mat-form-field class="example-full-width">
            <mat-label>
              Kilometraż
            </mat-label>
            <input formControlName="schemeLocation" matInput type="number" max="140" min="0" placeholder="Kilometraż zdarzenia">
          </mat-form-field>
          <div>Wpisz kilometraż zdarzenia w formacie XXX,xxx</div>
          <div>na podstawie lokalizacji danego znaku zostanie obliczona jego odległość od miejsca zdarenia która zostanie wstawiona w miejsce znaku X w treści znaku</div>
        </div>
        <div>
          <button mat-button (click)="onCloseClick()">Zamknij</button>
          <button mat-button matStepperPrevious type="button">Wstecz</button>
          <button mat-button (click)="saveScheme()" type="button">Zapisz</button>
        </div>
      </form>
    </mat-step>

  </mat-horizontal-stepper>
</div>
