import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {map} from 'rxjs/operators';
import {User} from "../models/user";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  currentUser: User;

  users_update = new Observable<string>(
      observer => {
        setInterval(() =>
          observer.next(new Date().toString()), 1000);
      }
  );

  constructor(private httpClient: HttpClient) { }

  assignCurrentUser(userData) {
    this.currentUser = new User();
    Object.assign(this.currentUser, userData);
  }

  login(username: string, password: string) {
    return this.httpClient.get('login', {responseType: 'json', params: {
      "login": username, "password": password
    }}).pipe(map(value => {
      this.assignCurrentUser(value);
      return this.currentUser;
    }));
  }

  logout() {
    this.currentUser = null;
    return this.httpClient.get('logout', {responseType: 'json'});
  }

  check(): Observable<User> {
    return this.httpClient.get('logincheck', {responseType: 'json'}).pipe(map(val => {
      this.assignCurrentUser(val)
      return this.currentUser;
    }))
  }

  getUsers() {
    return this.httpClient.get(
      'userslist',
      {responseType: 'json'}
      )
  }

  getUser(userId) {
    return this.httpClient.get(`${'user/'}/${userId}`)
  }

  getCurrentUser() {
    return this.currentUser;
  }
}
