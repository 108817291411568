<div class="example-container">
  <form class="example-container" [formGroup]="options">


    <mat-form-field
      [floatLabel]="options.value.floatLabel">
      <input matInput placeholder="Użytkownik" required>
    </mat-form-field>

    <mat-form-field [floatLabel]="options.value.floatLabel">
      <input matInput placeholder="Imię i nazwisko">
    </mat-form-field>


    <mat-form-field
      [floatLabel]="options.value.floatLabel">
      <mat-select required>
        <mat-option value="option">Użytkownik</mat-option>
        <mat-option value="option">Kierownik</mat-option>
        <mat-option value="option">Administrator</mat-option>
      </mat-select>
      <mat-label>Rola</mat-label>
    </mat-form-field>

    <mat-checkbox>Aktywny</mat-checkbox>
    <div class="primary_button">
      <button mat-raised-button color="primary">Utwórz</button>
    </div>
  </form>

</div>
